import { logout } from './util';

import axios from 'axios';

import Cookies from 'js-cookie';

const ENABLE_MOCK = false;

export const HOST = process.env.REACT_APP_BACKEND_URL;

export const setHeaders = function (authToken, contentType = null)
{
    return {
        ...(contentType && { 'Content-Type': contentType }),
        ...(authToken && { 'Authorization': `${authToken}` })
    };
}

export const fetchGet = async function (url, callingFunctionName, returnHeader = false) {
    if (!ENABLE_MOCK) {
        try {
            const authToken = Cookies.get('xAuthToken');

            const headers = setHeaders(authToken);

            const options = {
                headers,
                withCredentials: true
            };

            const response = await axios.get(HOST + url, options);
            const json = response.data;

            return (returnHeader === false) ? json : { ...json, headers: response.headers };
        } catch (error) {
            return await errorProcess(error, callingFunctionName, fetchGet, this, arguments);
        }
    } else {
        try {
            const data = await import(`../data/${callingFunctionName}.json`);
            return data.default; // Assicurati che il JSON sia esportato come default
        } catch (error) {
            console.error(`Error loading mock data for function ${callingFunctionName}:`, error);
            throw error;
        }
    }
}
export const fetchPost = async function (url, body, callingFunctionName = '', returnHeader = false, passRefreshToken = false) {
    try {
        const authToken = (passRefreshToken) ? Cookies.get('jwtrt') : Cookies.get('xAuthToken');

        const headers = setHeaders(authToken, 'application/json');

        const options = {
            headers,
            withCredentials: true
        };

        const response = await axios.post(HOST + url, body, options);
        const json = response.data;

        return (returnHeader === false) ? json : { ...json, headers: response.headers };
    } catch (error) {
        return await errorProcess(error, callingFunctionName, fetchPost, this, arguments);
    }

}
export const fetchFormData = async function (url, formData, callingFunctionName = '') {
    try {
        const authToken = Cookies.get('xAuthToken');

        const headers = setHeaders(authToken, 'multipart/form-data');

        const options = {
            headers,
            withCredentials: true
        };
        const response = await axios.post(HOST + url, formData, options);
        return response.data;
    } catch (error) {
        return await errorProcess(error, callingFunctionName, fetchFormData, this, arguments);
    }
}
export const fetchDelete = async function (url, body, callingFunctionName = '') {
    try {
        const authToken = Cookies.get('xAuthToken');

        const headers = setHeaders(authToken, 'application/json');

        const options = {
            headers,
            data: body,
            withCredentials: true
        };
        const response = await axios.delete(HOST + url, options);
        return response.data;
    } catch (error) {
        return await errorProcess(error, callingFunctionName, fetchDelete, this, arguments);
    }
}
const errorProcess = async function (error, callingFunctionName = '', callingFunction, cfContext = false, cfArguments = false) {
    if (error.response.data.atExpired === true) {
        const ratResponse = await refreshAccessTokenFetch();
        Cookies.set('xAuthToken', ratResponse.headers.get('X-Auth-Token'), { expires: 1 });
        return await callingFunction.apply(cfContext, cfArguments);
    } else if (error.response.data.msg === "Invalid Token") {
        logout();
        throw error;
    } else {
        console.error(`Error in function ${callingFunctionName}:`, error);
        throw error;
    }
}
export const loginFetch = async (email, password) => {
    return fetchPost("/login", { email, password }, 'loginFetch', true);
};
export const guestLoginFetch = async (userId = false) => {
    if (userId !== false) {
        return fetchPost("/anonymousLogin", {userId}, 'guestLoginFetch', true);
    } else {
        return fetchPost("/anonymousLogin", {}, 'guestLoginFetch', true);
    }
};
export const registerFetch = async (username, email, password, type, restaurantId = []) => {
    return fetchPost("/register", { username, email, password, type, restaurantId }, 'registerFetch', true);
};
export const refreshAccessTokenFetch = async () => {
    return fetchPost("/refreshAccessToken", {}, 'refreshAccessTokenFetch', true, true);
};
export const sendNewVerificationEmailFetch = async (id) => {
    return fetchPost("/sendNewVerificationEmail", {id}, 'sendNewVerificationEmailFetch', true);
}